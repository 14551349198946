
import { Component, Mixins } from 'vue-property-decorator';
import HeaderInfosMixin from '../header-infos';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';
import { fileUtilitiesConfs } from '@/store/modules/easy-firestore/file-utilities-confs';
import { FILE_UTILITIES_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIVATED,
	CONF_CLIENT_VERSION,
	ENVIRONMENT,
	GCS_BUCKET,
	ID,
	NB_FILENAME_TEMPLATES,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class FileUtilitiesConfigurationsListingView extends Mixins(
	HeaderInfosMixin,
	ConfigurationCollectionMixin
) {
	moduleName: string = fileUtilitiesConfs.moduleName;
	overriddenColumns: string[] = ['id', 'gcs_bucket'];

	get routeName() {
		return FILE_UTILITIES_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [
			ENVIRONMENT,
			CONF_CLIENT_VERSION,
			ID,
			GCS_BUCKET,
			NB_FILENAME_TEMPLATES,
			{
				text: 'Nb Tasks',
				sortable: true,
				value: 'tasks.length',
			},
			ACTIVATED,
		];
	}
}
